import react, { useState, useEffect } from "react";
import Header from "../Components/Header";
import {
  Flex,
  Box,
  Button,
  VStack,
  Text,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  Select,
  Square,
  VisuallyHidden,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import checklist from "../assets/checklist.png";
import money from "../assets/money.png";
import box from "../assets/box.png";
import settings from "../assets/settings.png";
import report from "../assets/report.png";
import { useHistory } from "react-router-dom";
const LogPurchase = () => {
  const [date, setDate] = useState();
  const [name, setName] = useState();
  const [qty, setQty] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://imsbackend.hop.sh/api/inventory")
      .then((res) => res.json())
      .then((data) => setData(data.data));
  }, []);
  const history = useHistory();
  const toast = useToast();
  
  function decimalTest(e) {
    const r = (e - Math.floor(e)) !== 0;
    if(r) {
      return true
    } else {
      return false
    }
  }
  const handleSubmit = async () => {
    let item;
    let quantity;
    const now1 = moment().format("yyyy-MM-DD")
    const now = moment(now1);
    const formatDate = moment(date);
    if (!qty || !date || !name) {
      return toast({
        title: "Missing Data",
        description: "Please fill all fields",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
    if(now._i > formatDate._i) {
      return toast({
        title: "Invalid Date",
        description: "The date must be the current or a future date",
        status: "error",
        duration: 6000,
        isClosable: true
      })
    }
    if (qty < 1) {
      return toast({
        title: "Invalid Quantity",
        description: "Quantity must be greater than 1",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
    
    if(decimalTest(qty)) {
      return toast({
        title: "Invalid Quantity",
        description: "Quantity must not be a decimal",
        status: "error",
        duration: 6000,
        isClosable: true,
      })
    }
    
    data.forEach((d) => {
      if (d.furnitureName == name) {
        item = d.furnitureName;
        quantity = qty;
      }
    });

    let res = await fetch('https://imsbackend.hop.sh/api/purchase', {
      method: 'post',
      body: JSON.stringify({ date, item, qty }),
      headers: {
        "Content-Type": "application/json"
      }

    })
    //console.log(res)
    if(res.status === 201) {
    toast({
      title: "Successfully saved purchase",
      status:"success",
      duration: 6000,
      isClosable: true
    })
  } else {
     return toast({
      title: "An unexpected error occured",
      status: "error",
      duration: 6000,
      isClosable: true
    })
  }

  let res1 = await fetch('https://imsbackend.hop.sh/api/inventory/add', {
    method: 'post',
    body: JSON.stringify({item, qty}),
    headers: {
      "Content-Type": "application/json"
    }
  })
  
  if(res1.status !== 200) return toast({title: "An unexpected error occured", status: "error", duration: 6000, isClosable: true})

  setDate("")
  setName("")
  setQty("")
    

   
  };

  return (
    <div style={{ backgroundColor: "#3b3b3b" }}>
      <Header />

      <Flex width={"40vh"} bg="#3b3b3b">
        <Box minH={"93vh"} minW="40vh" bg="#262525" overflow={"hidden"}>
          <VStack spacing={"55px"} align={"center"} paddingTop={"5vh"}>
            <Button
              bg="#737373"
              color={"white"}
              height={"90px"}
              width={"25vh"}
              onClick={(e) => history.push("/inventorystatus")}
            >
              <img src={checklist} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Inventory Status
              </Text>
            </Button>

            <Button
              bg="#737373"
              color={"white"}
              height={"90px"}
              width={"25vh"}
              onClick={(e) => history.push("/logsale")}
            >
              <img src={money} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Log Sale
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/logpurchase')}>
              <img src={box} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Log Purchase
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/admin')}>
              <img src={settings} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Admin
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/salesreport')}>
              <img src={report} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Sales Report
              </Text>
            </Button>
          </VStack>
        </Box>

        <Flex>
          <Box alignSelf={"center"}>
            <Square w="110vh">
              <VStack
                spacing="12px"
                color={"black"}
                h="500px"
                bg="#3b3b3b"
                justifyContent="center"
              >
                <Box
                  bg="none"
                  w="50vh"
                  h="40vh"
                  borderRadius={"12px"}
                  borderWidth={"20px"}
                  borderColor={"#3b3b3b"}
                >
                  <Text
                    p="none"
                    fontFamily={"Roboto"}
                    fontSize={"4xl"}
                    color="white"
                  >
                    Log Purchase
                  </Text>

                  <FormControl id="date" isRequired>
                    <FormLabel paddingTop="3vh" color="white">
                      Date
                    </FormLabel>
                    <Input
                      type="date"
                      placeholder="Enter the date"
                      color={"black"}
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl id="itemname" isRequired paddingTop="2vh">
                    <FormLabel color="white">Item Name</FormLabel>
                    <InputGroup>
                      <Select
                        placeholder="Select option"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      >
                        {data.map((d) => (
                          <option value={d.furnitureName}>
                            {d.furnitureName}
                          </option>
                        ))}
                      </Select>
                    </InputGroup>
                  </FormControl>
                  <FormControl id="quantity" isRequired paddingTop="2vh">
                    <FormLabel color="white">Quantity</FormLabel>
                    <InputGroup>
                      <VisuallyHidden></VisuallyHidden>
                      <Input
                        type="number"
                        placeholder="Enter the quantity"
                        color={"black"}
                        value={qty}
                        onChange={(e) => {
                          setQty(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormControl>
                </Box>
              </VStack>
            </Square>
            <Button
              bg="#505050"
              width="35%"
              color="white"
              onClick={handleSubmit}
              isLoading={loading}
            >
              Save
            </Button>
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default LogPurchase;
