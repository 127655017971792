import { Text, Button, Flex, Spacer, Tooltip } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import homeIcon from "../assets/home.png";
import logoutIcon from "../assets/logout.png";
const Header = () => {
  const history = useHistory();

  const handleHomeClick = () => {
    history.push("/landing");
  };
  const handleLogoutClick = () => {
    history.push("/");
  };
  return (
    <Flex bg="#262525" h="7vh">
      <Tooltip label="Home">
        <Button
          bg="none"
          alignSelf={"center"}
          _hover={{ bg: "none" }}
          onClick={handleHomeClick}
        >
          <img src={homeIcon} alt="homeicon" height="35px" width="35px"></img>
        </Button>
      </Tooltip>
      <Spacer />
      <Text
        color="white"
        alignSelf={"center"}
        fontFamily={"Roboto"}
        fontSize={"5xl"}
      >
        <span style={{fontFamily:"Pacifico"}}>STAR</span> Furniture
      </Text>
      <Spacer />
      <Tooltip label="Logout">
        <Button
          bg="none"
          alignSelf={"center"}
          _hover={{ bg: "none" }}
          onClick={handleLogoutClick}
        >
          <img src={logoutIcon} alt="logouticon" height="35px" width="35px"></img>
        </Button>
      </Tooltip>
    </Flex>
  );
};

export default Header;
