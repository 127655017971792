import react, { useEffect, useState } from 'react';
import Header from "../Components/Header";
import {
  Flex,
  Box,
  Button,
  VStack,
  Text,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
} from "@chakra-ui/react";
import checklist from "../assets/checklist.png";
import money from "../assets/money.png";
import box from "../assets/box.png";
import settings from "../assets/settings.png";
import report from "../assets/report.png";
import { useHistory } from "react-router-dom";

const InventoryStatus = () => {
  const history = useHistory()
  const [data, setData] = useState([])
    
    
    useEffect(() => {
      fetch('https://imsbackend.hop.sh/api/inventory')
      .then((res) => res.json())
      .then((data) => setData(data.data))
    }, [])
   
    
    
    return (
        <div style={{backgroundColor: "#3b3b3b"}}>
            <Header/>
            <Flex width={"40vh"} bg="#3b3b3b">
        <Box minH={"93vh"} minW="40vh" bg="#262525" overflow={"hidden"}>
          <VStack spacing={"55px"} align={"center"} paddingTop={"5vh"}>
            <Button
              bg="#737373"
              color={"white"}
              height={"90px"}
              width={"25vh"}
              onClick={(e) => history.push("/inventorystatus")}
            >
              <img src={checklist} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Inventory Status
              </Text>
            </Button>

            <Button 
            bg="#737373" 
            color={"white"} 
            height={"90px"} 
            width={"25vh"}
            onClick={(e) => history.push('/logsale')}
            >
              <img src={money} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Log Sale
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/logpurchase')}>
              <img src={box} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Log Purchase
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/admin')}>
              <img src={settings} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Admin
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/salesreport')}>
              <img src={report} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Sales Report
              </Text>
            </Button>
          </VStack>
        </Box>

        <Flex>
          <Box alignSelf={"center"} paddingLeft={"35vh"}>
            <Text fontSize={"5xl"} color={"white"} fontFamily={"Roboto"}>Inventory Status</Text>
           <TableContainer>
            <Table variant={"simple"} color={"white"} fontFamily={"Roboto"} fontSize={"3xl"} size={"lg"}>
              <Thead>
                <Tr>
                  <Tooltip label="Name of furniture in stock" placement='top' fontSize={"xl"}>
                  <Th color={"white"} fontFamily={"Roboto"} fontSize={"4xl"} background={"#717171"}>Name</Th>
                  </Tooltip>
                  <Tooltip label="Amount of furniture in stock" placement='top' fontSize={"xl"}>
                  <Th color={"white"} fontFamily={"Roboto"} fontSize={"4xl"} background={"#717171"}>Quantity</Th>
                  </Tooltip>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((d) => (
                  <Tr>
                    <Td>{d.furnitureName}</Td>
                    <Td isNumeric>{d.furnitureQuantity}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
           </TableContainer>
          </Box>
        </Flex>
        </Flex>
        </div>
    )
}

export default InventoryStatus;