import react, { useEffect, useState } from "react";
import Header from "../Components/Header";
import {
    Flex,
  Box,
  Button,
  VStack,
  Text,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
} from "@chakra-ui/react";
import checklist from "../assets/checklist.png";
import money from "../assets/money.png";
import box from "../assets/box.png";
import settings from "../assets/settings.png";
import report from "../assets/report.png";
import { useHistory } from "react-router-dom";
const SalesReport = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        fetch("https://imsbackend.hop.sh/api/sales")
          .then((res) => res.json())
          .then((data) => setData(data.data));
      }, []);
  const history = useHistory();
  return (
    <div style={{ backgroundColor: "#3b3b3b" }}>
      <Header />

      <Flex width={"40vh"} bg="#3b3b3b">
        <Box minH={"93vh"} minW="40vh" bg="#262525" overflow={"hidden"}>
          <VStack spacing={"55px"} align={"center"} paddingTop={"5vh"}>
            <Button
              bg="#737373"
              color={"white"}
              height={"90px"}
              width={"25vh"}
              onClick={(e) => history.push("/inventorystatus")}
            >
              <img src={checklist} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Inventory Status
              </Text>
            </Button>

            <Button 
            bg="#737373" 
            color={"white"} 
            height={"90px"} 
            width={"25vh"}
            onClick={(e) => history.push('/logsale')}
            >
              <img src={money} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Log Sale
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/logpurchase')}>
              <img src={box} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Log Purchase
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/admin')}>
              <img src={settings} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Admin
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/salesreport')}>
              <img src={report} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Sales Report
              </Text>
            </Button>
          </VStack>
        </Box>

        <Flex>
          <Box alignSelf={"center"}>
          <Box alignSelf={"center"} paddingLeft={"25vh"}>
            <Text fontSize={"5xl"} color={"white"} fontFamily={"Roboto"}>Sales Report</Text>
           <TableContainer>
            <Table variant={"simple"} color={"white"} fontFamily={"Roboto"} fontSize={"3xl"} size={"lg"}>
              <Thead>
                <Tr>
                  <Tooltip label="Date of sale" placement='top' fontSize={"xl"}>
                  <Th color={"white"} fontFamily={"Roboto"} fontSize={"4xl"} background={"#717171"}>Date</Th>
                  </Tooltip>

                  <Tooltip label="Name of item sold" placement='top' fontSize={"xl"}>
                  <Th color={"white"} fontFamily={"Roboto"} fontSize={"4xl"} background={"#717171"}>Item</Th>
                  </Tooltip>

                  <Tooltip label="Quantity of item sold" placement='top' fontSize={"xl"}>
                  <Th color={"white"} fontFamily={"Roboto"} fontSize={"4xl"} background={"#717171"}>Quantity</Th>
                  </Tooltip>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((d) => (
                  <Tr>
                    <Td>{d.saleDate}</Td>
                    <Td>{d.saleItem}</Td>
                    <Td>{d.saleQuantity}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
           </TableContainer>
          </Box>
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default SalesReport;
