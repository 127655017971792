import react from "react";
import Header from "../Components/Header";
import {
  Flex,
  Box,
  Button,
  VStack,
  Text,
} from "@chakra-ui/react";
import checklist from "../assets/checklist.png";
import money from "../assets/money.png";
import box from "../assets/box.png";
import settings from "../assets/settings.png";
import report from "../assets/report.png";
import { useHistory } from "react-router-dom";
const Landing = () => {
  const history = useHistory();
  return (
    <div style={{ backgroundColor: "#3b3b3b" }}>
      <Header />

      <Flex width={"40vh"} bg="#3b3b3b">
        <Box minH={"93vh"} minW="40vh" bg="#262525" overflow={"hidden"}>
          <VStack spacing={"55px"} align={"center"} paddingTop={"5vh"}>
            <Button
              bg="#737373"
              color={"white"}
              height={"90px"}
              width={"25vh"}
              onClick={(e) => history.push("/inventorystatus")}
            >
              <img src={checklist} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Inventory Status
              </Text>
            </Button>

            <Button 
            bg="#737373" 
            color={"white"} 
            height={"90px"} 
            width={"25vh"}
            onClick={(e) => history.push('/logsale')}
            >
              <img src={money} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Log Sale
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/logpurchase')}>
              <img src={box} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Log Purchase
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/admin')}>
              <img src={settings} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Admin
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/salesreport')}>
              <img src={report} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Sales Report
              </Text>
            </Button>
          </VStack>
        </Box>

        <Flex>
          <Box alignSelf={"center"}>
            <Text
              color={"white"}
              paddingLeft={"40vh"}
              fontFamily={"Roboto"}
              fontSize={"8xl"}
            >
              Inventory Management System
            </Text>
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default Landing;
