import { useState, useEffect } from "react";
import Header from "../Components/Header";
import {
  Flex,
  Box,
  Button,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Select,
  Tooltip
} from "@chakra-ui/react";
import checklist from "../assets/checklist.png";
import money from "../assets/money.png";
import box from "../assets/box.png";
import settings from "../assets/settings.png";
import report from "../assets/report.png";
import addItemIcon from '../assets/add-to-cart.png';
import remItemIcon from '../assets/package.png';
import addUserIcon from '../assets/add-user.png'
import { useHistory } from "react-router-dom";

const Admin = () => {
    const {isOpen: isAddItemOpen, onClose: onAddItemClose, onOpen: onAddItemOpen} = useDisclosure()
    const {isOpen: isRemItemOpen, onClose: onRemItemClose, onOpen: onRemItemOpen} = useDisclosure()
    const {isOpen: isAddUserOpen, onClose: onAddUserClose, onOpen: onAddUserOpen} = useDisclosure()
    const {isOpen: isPasswordOpen, onClose: onPasswordClose, onOpen: onPasswordOpen} = useDisclosure({defaultIsOpen: true})

    const [item, setItem] = useState()
    const [remItem, setRemItem] = useState()
    const [qty, setQty] = useState()
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [pin, setPin] = useState()
    const [data, setData] = useState([])
    
  const history = useHistory();
    const toast = useToast()
    useEffect(() => {
        fetch("https://imsbackend.hop.sh/api/inventory")
          .then((res) => res.json())
          .then((data) => setData(data.data));
      }, []);

      function decimalTest(e) {
        const r = (e - Math.floor(e)) !== 0;
        if(r) {
          return true
        } else {
          return false
        }
      }

    const handleAddItem = async() => {
        if(!item || !qty) {
            return toast({
                title: "Missing Fields",
                description: "Please fill all fields",
                status: "error",
                duration: 6000,
                isClosable: true
            })
        }
        if(isNaN(qty)) {
          return toast({
            title: "Invalid Quantity",
            description: "Quantity must be a number",
            status: "error",
            duration: 6000,
            isClosable: true
          })
        }
        if(decimalTest(qty)) {
          return toast({
            title: "Invalid Quantity",
            description: "Quantity must not be a decimal",
            status: "error",
            duration: 6000,
            isClosable: true,
          })
        }
        if(qty < 0) {
            return toast({
                title: "Invalid Quantity",
                description: "Item quantity must be greater than zero",
                status: "error",
                duration: 6000,
                isClosable: true
            })

        }
   try {
    let res1 = await fetch("https://imsbackend.hop.sh/api/inventory?item=" + item)
    let inventoryData = await res1.json()
    if(res1.status  === 200) {
      return toast({
        title: "Invalid Name",
        description: "An item with the same name exists",
        status: "error",
        duration: 6000,
        isClosable: true
      })
    }
    } catch(e) {
    }

        let res = await fetch("https://imsbackend.hop.sh/api/addinventory", {
            method: 'post',
            body: JSON.stringify({item, qty}),
            headers: {
                "Content-Type": "application/json"
            }
        })
        
        if(res.status === 201) {
            toast({
                title: "Successfully added new item",
                status: 'success',
                duration: 6000,
                isClosable: true
            })
            onAddItemClose()
        } else {
           return toast({
                title: "An unexpected error occured",
                status: 'error',
                duration: 6000,
                isClosable: true
            })
        }
    }
    
  
   
      
  const handleRemItem = async() => {
    if(!remItem) {
      return toast({
        title: "Missing fields",
        description: "Please fill all fields",
        status: "error",
        duration: 6000,
        isClosable: true
      })
    }
    let res = await fetch('https://imsbackend.hop.sh/api/reminventory', {
        method: 'post',
        body: JSON.stringify({remItem}),
        headers: {
            "Content-Type": "application/json"
        }
    })
    if(res.status === 200) {
        toast({
            title: "Successfully deleted item",
            status: "success",
            duration: 6000,
            isClosable: true
        })
        onRemItemClose()
    } else {
        return toast({
            title: "An unexpected error occured",
            status: 'error',
            duration: 6000,
            isClosable: true
        })
    }
  }

  const handleAddUser = async() => {
    if(!username || !password) {
      return toast({
        title: "Missing fields",
        description: "Please fill all fields",
        status: "error",
        duration: 6000,
        isClosable: true
      })
    }
    let res = await fetch('https://imsbackend.hop.sh/api/adduser', {
        method: 'post',
        body: JSON.stringify({username, password}),
        headers: {
            "Content-Type": "application/json"
        }
    })

    if(res.status === 201) {
        toast({
            title: "Successfully created user login",
            status: "success",
            duration: 6000,
            isClosable: true
        })
        onAddUserClose()
    } else if(res.status === 409) {
        return toast({
            title: "User already exists",
            description: "An user with the same username already exists",
            status: 'error',
            duration: 6000,
            isClosable: true
        })
    } else {
        return toast({
            title: "An unexpected error occured",
            status: 'error',
            duration: 6000,
            isClosable: true
        })
    }
  }

  const handlePinLogin = async() => {
    if(!pin ) {
      return toast({
        title: "Missing fields",
        description: "Please fill all fields",
        status: "error",
        duration: 6000,
        isClosable: true
      })
    }
    if(pin === "397813") {
      toast({
        title: "Authorized",
        status: "success",
        duration: 6000,
        isClosable: true
      })
      onPasswordClose()
    } else {
      toast({
        title: "Unauthorized Access",
        status: "warning",
        duration: 6000,
        isClosable: true
      })
      history.push('/landing')
    }
  }

  const handlePinLogout = async() => {
    toast({
      title: "Unauthorized Access",
      status: "warning",
      duration: 6000,
      isClosable: true
    })
    history.push('/landing')
  }
  return (
    <div style={{ backgroundColor: "#3b3b3b" }}>
      <Header />

      <Flex width={"40vh"} bg="#3b3b3b">
        <Box minH={"93vh"} minW="40vh" bg="#262525" overflow={"hidden"}>
          <VStack spacing={"55px"} align={"center"} paddingTop={"5vh"}>
            <Button
              bg="#737373"
              color={"white"}
              height={"90px"}
              width={"25vh"}
              onClick={(e) => history.push("/inventorystatus")}
            >
              <img src={checklist} alt="" height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Inventory Status
              </Text>
            </Button>

            <Button 
            bg="#737373" 
            color={"white"} 
            height={"90px"} 
            width={"25vh"}
            onClick={(e) => history.push('/logsale')}
            >
              <img src={money} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Log Sale
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/logpurchase')}>
              <img src={box} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Log Purchase
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/admin')}>
              <img src={settings} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Admin
              </Text>
            </Button>

            <Button bg="#737373" color={"white"} height={"90px"} width={"25vh"} onClick={(e) => history.push('/salesreport')}>
              <img src={report} height={"45px"} width={"45px"}></img>
              <Text fontFamily={"Roboto"} fontWeight={"thin"} fontSize={"2xl"}>
                Sales Report
              </Text>
            </Button>
          </VStack>
        </Box>

        <Flex flexDirection={"row"}>
         <Box alignSelf={"center"} paddingRight={"20vh"} paddingLeft={"10vh"}>
          <Tooltip label="Add inventory item" placement="top" fontSize={"xl"}>
          <Button
          bg="none"
          alignSelf={"center"}
          _hover={{ bg: "none" }}
          onClick={onAddItemOpen}
          width= "200px"
          height= "200px"
        >
          <img src={addItemIcon} height="200px" width={"200px"}></img>
        </Button>
        </Tooltip>
        </Box>
        

    <Box alignSelf={"center"} paddingRight={"20vh"}>
      <Tooltip label="Delete inventory item" placement="top" fontSize={"xl"}>
        <Button
          bg="none"
          alignSelf={"center"}
          _hover={{ bg: "none" }}
          onClick={onRemItemOpen}
          width= "200px"
          height= "200px"
        >
          <img src={remItemIcon} height="200px" width={"200px"}></img>
        </Button>
        </Tooltip>
        </Box>

        <Box alignSelf={"center"}>
          <Tooltip label="Add login detail" placement="top" fontSize={"xl"}>
        <Button
          bg="none"
          alignSelf={"center"}
          _hover={{ bg: "none" }}
          onClick={onAddUserOpen}
          width= "200px"
          height= "200px"
        >
          <img src={addUserIcon} height="200px" width={"200px"}></img>
        </Button>
        </Tooltip>
        </Box>
        </Flex>
      </Flex>

    {/* PASSWORD MODAL */}
    <Modal isOpen={isPasswordOpen} onClose={handlePinLogout}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter={"blur(20px)"}>
            <ModalContent>
                <ModalHeader>Enter Admin Password</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <FormControl isRequired>
                        <FormLabel>Pin</FormLabel>
                        <Input onChange={(e) => setPin(e.target.value)} type="password"></Input>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={handlePinLogin} mr={3} variant={"solid"} colorScheme="green">
                        Login
                    </Button>
                    <Button onClick={handlePinLogout} variant={"outline"} colorScheme="red">
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </ModalOverlay>
    </Modal>

    {/* ADD ITEM MODAL */}
      <Modal isOpen={isAddItemOpen} onClose={onAddItemClose}>
        <ModalOverlay >
            <ModalContent>
                <ModalHeader>Add Inventory Item</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <FormControl isRequired>
                        <FormLabel>Item Name</FormLabel>
                        <Input placeholder="item name" onChange={(e) => setItem(e.target.value)} ></Input>
                    </FormControl>
                    <FormControl isRequired mt={4}>
                        <FormLabel>Quantity</FormLabel>
                        <Input placeholder="item quantity" onChange={(e) => setQty(e.target.value)}></Input>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={handleAddItem} mr={3} variant={"solid"} colorScheme="green">
                        Save
                    </Button>
                    <Button onClick={onAddItemClose} variant={"outline"} colorScheme="red">
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </ModalOverlay>
    </Modal>

    {/* REMOVE ITEM MODAL */}
    <Modal isOpen={isRemItemOpen} onClose={onRemItemClose}>
        <ModalOverlay >
            <ModalContent>
                <ModalHeader>Remove Inventory Item</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                <Select
                        placeholder="Select option"
                        onChange={(e) => setRemItem(e.target.value)}
                      >
                        {data.map((d) => (
                          <option value={d.furnitureName}>
                            {d.furnitureName}
                          </option>
                        ))}
                        </Select>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={handleRemItem} mr={3} variant={"solid"} colorScheme="green">
                        Save
                    </Button>
                    <Button onClick={onRemItemClose} variant={"outline"} colorScheme="red">
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </ModalOverlay>
    </Modal>


    {/* ADD USER MODAL */}
    <Modal isOpen={isAddUserOpen} onClose={onAddUserClose}>
        <ModalOverlay >
            <ModalContent>
                <ModalHeader>Add User</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <FormControl isRequired>
                        <FormLabel>Username</FormLabel>
                        <Input placeholder="username" onChange={(e) => setUsername(e.target.value)} ></Input>
                    </FormControl>
                    <FormControl isRequired mt={4}>
                        <FormLabel>Password</FormLabel>
                        <Input placeholder="password" onChange={(e) => setPassword(e.target.value)}></Input>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={handleAddUser} mr={3} variant={"solid"} colorScheme="green">
                        Save
                    </Button>
                    <Button onClick={onAddUserClose} variant={"outline"} colorScheme="red">
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </ModalOverlay>
    </Modal>
    </div>
  );
};

export default Admin;
