import "./App.css";
import Login from "./Pages/Login";
import Landing from "./Pages/Landing";
import InventoryStatus from "./Pages/InventoryStatus";
import LogSale from "./Pages/LogSale";
import LogPurchase from "./Pages/LogPurchase";
import Admin from "./Pages/Admin";
import SalesReport from "./Pages/SalesReport"
import { Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Route path="/" component={Login} exact />
      <Route path="/landing" component={Landing} />
      <Route path="/inventorystatus" component={InventoryStatus}/>
      <Route path="/logsale" component={LogSale}/>
      <Route path='/logpurchase' component={LogPurchase}/>
      <Route path='/admin' component={Admin}/>
      <Route path='/salesreport' component={SalesReport}/>
    </div>
  );
}

export default App;
