import react, { useState } from "react";
import {
  Center,
  Text,
  Box,
  Flex,
  VStack,
  Image,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Button,
  Square,
  useToast,
} from "@chakra-ui/react";
import couch from "../assets/login_couch.png";
import { useHistory } from "react-router-dom";
const Login = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const toast = useToast();
  const history = useHistory();
  const handleSubmit = async () => {
    if(!username || !password) {
      return toast({
        title: "No username or password detected",
        status: "error",
        duration: 6000,
        isClosable: true
      })
    }
    let res;
    try {
    res = await fetch("https://imsbackend.hop.sh/api/login", {
      method: "post",
      body: JSON.stringify({ username, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    if(res.status == 200) {
      toast({
        title: "Login successful",
        status: "success",
        duration: 6000,
        isClosable: true
      })
      history.push('/landing')
    } else if(res.status == 401) {
      toast({
        title: "Invalid username or password",
        status: "error",
        duration: 6000,
        isClosable: true
      })
    } else {
      toast({
        title: "An unexpected error occured",
        status: "error",
        duration: 6000,
        isClosable: true
      })
    }
  } catch(e) {
    return toast({
      title: "Error occured",
      status: "error",
      duration: 6000,
      isClosable: true
    })
  }
   
  };
  return (
    <div>
      <Flex bg="#3b3b3b">
        <Box minH={"100vh"} minW="50vh" bg="#262525" overflow={"hidden"}>
          <Text
            bg="none"
            paddingTop="15vh"
            color={"white"}
            fontFamily={"Roboto"}
            fontSize={"6xl"}
          >
            <span style={{fontFamily:"Pacifico"}}>STAR</span>
          </Text>
          <Text
            bg="none"
            color={"white"}
            fontFamily={"Roboto"}
            fontSize={"6xl"}
          >
            FURNITURE
          </Text>
          <Center bg="none">
            <Image
              src={couch}
              alt="couch image"
              paddingTop="15vh"
              bg="none"
            ></Image>
          </Center>
        </Box>
        {/*/////////////////////////////////////////////////////////////////////////////////*/}
        <Flex>
          <Square w="110vh">
            <VStack
              spacing="12px"
              color={"black"}
              h="500px"
              bg="none"
              justifyContent="center"
            >
              <Box
                bg="#262525"
                w="50vh"
                h="40vh"
                borderRadius={"12px"}
                borderWidth={"20px"}
                borderColor={"#262525"}
              >
                <Text
                  p="none"
                  fontFamily={"Roboto"}
                  fontSize={"4xl"}
                  color="white"
                >
                  Login
                </Text>
                <FormControl id="username" isRequired>
                  <FormLabel paddingTop="3vh" color="white">
                    Username
                  </FormLabel>
                  <Input
                    placeholder="Enter your Username"
                    color={"white"}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </FormControl>

                <FormControl id="password" isRequired paddingTop="2vh">
                  <FormLabel color="white">Password</FormLabel>
                  <InputGroup>
                    <Input
                      placeholder="Enter your Password"
                      color={"white"}
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </InputGroup>
                </FormControl>

                <Button
                  bg="#505050"
                  width="35%"
                  style={{ marginTop: 15 }}
                  color="white"
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Box>
            </VStack>
          </Square>
        </Flex>
      </Flex>
    </div>
  );
};

export default Login;
